import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale, params.feePercentage);
      const vatTaxableAmounts = [feeAmount, params.variousAmount];
      const registrationAmount = this.getRegistrationAmount(params.regHyp, params.annex);
      const vatAmount = this.calculateVat(vatTaxableAmounts, params.vatPercentage);
      return [
        {
          labelLocale: 'label.regrog',
          value: this.formatNumberToCurrency(registrationAmount)
        },
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.mortgage',
          value: this.formatNumberToCurrency(params.mortgage)
        },
        {
          labelLocale: 'label.various',
          value: this.formatNumberToCurrency(params.variousAmount)
        },
        {
          ...this.getVatOutputLocale(vatTaxableAmounts),
          value: this.formatNumberToCurrency(vatAmount)
        },
        ...this.getProvisionOutputSectionConfigs(
          params.amount,
          [params.regHyp, feeAmount, params.variousAmount, vatAmount, params.mortgage]
        )
      ];
    },

    getRegistrationAmount(regAmmount, annexAmount) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(regAmmount).plus(annexAmount)
      );
    }
  }
};
