export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const registrationAmount = this.getRegistrationAmount(
        params.regHyp,
        params.amount,
        params.annex,
        params.abatement
      );
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale, params.feePercentage);
      const vatTaxableAmounts = [feeAmount, params.variousAmount, params.rog];
      const vatAmount = this.calculateVat(vatTaxableAmounts, params.vatPercentage);
      return [
        {
          labelLocale: 'label.reg',
          value: this.formatNumberToCurrency(registrationAmount)
        },
        {
          labelLocale: 'label.rog',
          value: this.formatNumberToCurrency(params.rog)
        },
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.mortgage',
          value: this.formatNumberToCurrency(params.mortgage)
        },
        {
          labelLocale: 'label.various',
          value: this.formatNumberToCurrency(params.variousAmount)
        },
        {
          ...this.getVatOutputLocale(vatTaxableAmounts),
          value: this.formatNumberToCurrency(vatAmount)
        },
        ...this.getProvisionOutputSectionConfigs(
          params.amount,
          [
            registrationAmount,
            feeAmount,
            params.variousAmount,
            vatAmount,
            params.rog,
            params.mortgage
          ]
        )
      ];
    }
  }
};
