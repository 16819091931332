<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label">{{ $t('label.regrog') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <input
            class="input is-static"
            :value="this.formatNumberToCurrency(regHyp)"
            readonly
          >
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="em-feepercentage">{{ $t('label.fee') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="em-feepercentage" v-model="feePercentage">
              <option
                v-for="option in this.getSelectOptions('fees10050')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="em-annex">{{ $t('label.annex') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="em-annex" v-model="annex">
              <option
                v-for="option in this.getSelectOptions('annexKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="em-various">{{ $t('label.various') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="em-various"
            class="input"
            v-model="various"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="em-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="em-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedEmancipationCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedEmancipationCalculation],
  data() {
    return {
      feeScale: 'B',
      regHyp: this.getAppSetting('emancipationRegAmount'),
      feePercentage: 100,
      various: this.getDefaultVariousAmount(),
      amount: null,
      annex: 0
    };
  },
  methods: {
    isInputFormValid() {
      return this.amount;
    },
    getCalculationParams() {
      const {
        amount,
        regHyp,
        feePercentage,
        feeScale,
        annex
      } = this;
      const { vatPercentage, emancipationMortgage } = this.getAppSettings();
      const variousAmount = this.getNumericVariousAmount();
      return {
        amount,
        regHyp,
        variousAmount,
        feeScale,
        feePercentage,
        vatPercentage,
        mortgage: emancipationMortgage,
        annex
      };
    }
  }
};
</script>
