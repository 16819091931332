export default {
  vatPercentage: 21,
  emancipationRegAmount: 75,
  additionalCompanyFormationRegAmount: 50,
  minFeeAmount: 8.55,
  donationMortgage: 240,
  exchangeMortgage: 240,
  emancipationMortgage: 297,
  distributionMortgage: 240,
  saleMortgage: 240,
  companyFormationMortgage: 240,
  loanMortgageFixedMin: 230,
  loanMortgageFixedMax: 985,
  loanMortgageVariablePercentage: 0.3,
  loanMortgageDelimiterAmount: 300000,
  openingCreditMortgageFixedMin: 230,
  openingCreditMortgageFixedMax: 985,
  openingCreditMortgageVariablePercentage: 0.3,
  openingCreditMortgageDelimiterAmount: 300000
};
