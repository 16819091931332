import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';
import SharedScalesUtil from '../util/Scales';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      return [
        {
          labelLocale: this.getInterestCalculationTitle(),
          value: `${this.formatNumberToCurrency(
            this.calculateInterest(
              params.interestRate,
              params.isMonthlyInterestPeriod
            )
          )}%`
        },
        {
          labelLocale: 'label.downpayment',
          value: this.formatNumberToCurrency(
            this.calculateFixedInstallment(
              params.amount,
              params.interestRate,
              params.isMonthlyPaymentTerm,
              params.isMonthlyInterestPeriod,
              params.nrOfPayments
            )
          )
        }
      ];
    },

    calculateInterest(interestRate, isMonthlyInterestPeriod) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(interestRate)
        .dividedBy(100)
        .plus(1)
        .pow(isMonthlyInterestPeriod ? 12 : new Decimal(1).dividedBy(12))
        .minus(1)
        .times(100)
      );
    },

    calculateFixedInstallment(amount, interestRate, isMonthlyPaymentTerm, isMonthlyInterestPeriod, nrOfPayments) {
      const termInterestRateDecimal = new Decimal(
        this.getPaymentTermInterestRate(interestRate, isMonthlyPaymentTerm, isMonthlyInterestPeriod)
      ).dividedBy(100);
      return SharedNumericUtil.decimalToNumber(
        new Decimal(amount)
        .times(
          termInterestRateDecimal
          .dividedBy(
            termInterestRateDecimal
            .plus(1)
            .pow(nrOfPayments)
            .minus(1)
          )
          .plus(termInterestRateDecimal)
        )
      );
    },

    getPaymentTermInterestRate(interestRate, isMonthlyPaymentTerm, isMonthlyInterestPeriod) {
      let paymentTermInterestRate;
      if (isMonthlyPaymentTerm) {
        if (isMonthlyInterestPeriod) {
          paymentTermInterestRate = interestRate;
        }
        else {
          paymentTermInterestRate = this.calculateInterest(interestRate, isMonthlyInterestPeriod);
        }
      }
      else if (!isMonthlyInterestPeriod) {
        paymentTermInterestRate = interestRate;
      }
      else {
        paymentTermInterestRate = this.calculateInterest(interestRate, isMonthlyInterestPeriod);
      }
      return paymentTermInterestRate;
    },

    getInterestCalculationTitle() {
      return `label.${this.isMonthlyInterestPeriod ?
          'yearlyinterestrate' :
          'monthlyinterestrate'}`;
    }
  }
};
