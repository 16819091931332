<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="vu-ageinterval">{{ $t('label.age') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="vu-ageinterval" v-model="ageInterval">
              <option
                v-for="option in this.getSelectOptions('usufructAgeIntervals')"
                :value="option.id"
                :key="option.id"
                v-html="option.title"
              ></option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="vu-amount">{{ $t('label.propertyworth') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="vu-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedValueOfUsufructCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedValueOfUsufructCalculation],
  data() {
    return {
      ageInterval: 3,
      amount: null
    };
  },
  methods: {
    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      return {
        amount: this.amount,
        ageInterval: this.ageInterval
      };
    }
  }
};
</script>
