<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-reghyp">{{ $t('label.reg') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="sl-reghyp" v-model="regHyp">
              <option
                v-for="option in this.getSelectOptions('regHypSale')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-rog">{{ $t('label.rog') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="sl-rog" v-model="rog">
              <option
                v-for="option in this.getSelectOptions('rogKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-feepercentage">{{ $t('label.fee') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="sl-feepercentage" v-model="feePercentage">
              <option
                v-for="option in this.getSelectOptions('fees10050')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-abatement">{{ $t('label.abatement') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="sl-abatement" v-model="abatement">
              <option
                v-for="option in this.getSelectOptions('saleAbatement')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-annex">{{ $t('label.annex') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="sl-annex" v-model="annex">
              <option
                v-for="option in this.getSelectOptions('annexKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-various">{{ $t('label.various') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="sl-various"
            class="input"
            v-model="various"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="sl-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="sl-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedSaleCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedSaleCalculation],
  data() {
    return {
      feeScale: 'J',
      regHyp: this.getUserSetting('standardRegHyp'),
      feePercentage: 100,
      abatement: 0,
      rog: 50,
      various: this.getDefaultVariousAmount(),
      amount: null,
      annex: 0
    };
  },
  methods: {
    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      const {
        regHyp,
        abatement,
        feePercentage,
        feeScale,
        amount,
        rog,
        annex
      } = this;
      const { vatPercentage, saleMortgage } = this.getAppSettings();
      const variousAmount = this.getNumericVariousAmount();
      return {
        regHyp,
        rog,
        abatement,
        feePercentage,
        amount,
        feeScale,
        vatPercentage,
        mortgage: saleMortgage,
        variousAmount,
        annex
      };
    }
  }
};
</script>
