import Decimal from 'decimal.js';
import ScalesList from '../store/Scales';

export default {
  getScaleConfig(scaleName) {
    return ScalesList[scaleName];
  },
  getTotalScaleFromAmount(scaleName, baseAmount) {
    let totalScaleDecimal = new Decimal(0);
    const scaleConfig = this.getScaleConfig(scaleName);

    for (let scaleStep = 0, len = scaleConfig.length; scaleStep < len; scaleStep++) {
      totalScaleDecimal = totalScaleDecimal.plus(
        new Decimal(this.getAmountToBeScaledOnStep(scaleStep, scaleName, baseAmount))
          .times(scaleConfig[scaleStep].percent)
          .dividedBy(100)
      );

      if (this.toStopScalingAtStep(scaleStep, scaleName, baseAmount)) {
        break;
      }
    }

    return totalScaleDecimal
      .toDecimalPlaces(2)
      .toNumber();
  },
  getAmountToBeScaledOnStep(scaleStepNumber, scaleName, baseAmount) {
    const scaleConfig = this.getScaleConfig(scaleName);
    const scaleStepAmount = scaleConfig[scaleStepNumber].value;
    const previousScaleStepConfig = scaleConfig[scaleStepNumber - 1];
    const previousStepScaleAmount = previousScaleStepConfig ? previousScaleStepConfig.value : 0;
    let scaleStepAmountMinuend;

    if (scaleStepAmount && new Decimal(baseAmount).greaterThanOrEqualTo(scaleStepAmount)) {
      scaleStepAmountMinuend = scaleStepAmount;
    }
    else {
      scaleStepAmountMinuend = baseAmount;
    }

    return new Decimal(scaleStepAmountMinuend).minus(previousStepScaleAmount);
  },
  toStopScalingAtStep(scaleStepNumber, scaleName, baseAmount) {
    const scaleStepAmount = this.getScaleConfig(scaleName)[scaleStepNumber].value;

    return scaleStepAmount && new Decimal(baseAmount).lessThan(scaleStepAmount);
  }
};
