<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fe-feescale">{{ $t('label.scale') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="fe-feescale" v-model="feeScale">
              <option
                v-for="option in this.getSelectOptions('feeScales')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fe-feepercentage">{{ $t('label.fee') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="fe-feepercentage" v-model="feePercentage">
              <option
                v-for="option in this.getSelectOptions('fees10050')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fe-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="fe-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedFeesCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedFeesCalculation],
  data() {
    return {
      feeScale: 'J',
      feePercentage: 100,
      amount: null
    };
  },

  methods: {
    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      const { amount, feeScale, feePercentage } = this;
      const { vatPercentage } = this.getAppSettings();
      return {
        amount,
        feeScale,
        feePercentage,
        vatPercentage
      };
    }
  }
};
</script>
