/**
 * Scales have letters that refer to the types of deeds they apply to. Scales are calculated as a
 * running percentage of the main value. The percentage in the last scale element will be applied
 * to the remaining amount after all previous scale percentages have been applied.
 */
export default {
  A: [
    { value: 7500, percent: 0.285 },
    { value: 17500, percent: 0.228 },
    { value: 30000, percent: 0.171 },
    { value: 45495, percent: 0.114 },
    { value: 64095, percent: 0.057 },
    { value: 250095, percent: 0.0228 },
    { percent: 0.0114 }
  ],
  B: [
    { value: 7500, percent: 0.399 },
    { value: 17500, percent: 0.342 },
    { value: 30000, percent: 0.228 },
    { value: 45495, percent: 0.171 },
    { value: 64095, percent: 0.114 },
    { value: 250095, percent: 0.057 },
    { percent: 0.0228 }
  ],
  C: [
    { value: 7500, percent: 0.57 },
    { value: 17500, percent: 0.456 },
    { value: 30000, percent: 0.342 },
    { value: 45495, percent: 0.228 },
    { value: 64095, percent: 0.114 },
    { value: 250095, percent: 0.057 },
    { percent: 0.0228 }
  ],
  D: [
    { value: 7500, percent: 0.855 },
    { value: 17500, percent: 0.684 },
    { value: 30000, percent: 0.456 },
    { value: 45495, percent: 0.342 },
    { value: 64095, percent: 0.228 },
    { value: 250095, percent: 0.114 },
    { percent: 0.0342 }
  ],
  E: [
    { value: 7500, percent: 1.14 },
    { value: 17500, percent: 0.855 },
    { value: 30000, percent: 0.57 },
    { value: 45495, percent: 0.456 },
    { value: 64095, percent: 0.285 },
    { value: 250095, percent: 0.114 },
    { percent: 0.0342 }
  ],
  F: [
    { value: 7500, percent: 1.425 },
    { value: 17500, percent: 1.14 },
    { value: 30000, percent: 0.684 },
    { value: 45495, percent: 0.57 },
    { value: 64095, percent: 0.456 },
    { value: 250095, percent: 0.228 },
    { percent: 0.0456 }
  ],
  G: [
    { value: 7500, percent: 1.71 },
    { value: 17500, percent: 1.368 },
    { value: 30000, percent: 0.912 },
    { value: 45495, percent: 0.684 },
    { value: 64095, percent: 0.456 },
    { value: 250095, percent: 0.228 },
    { percent: 0.0456 }
  ],
  H: [
    { value: 7500, percent: 2.85 },
    { value: 17500, percent: 1.71 },
    { value: 30000, percent: 1.425 },
    { value: 45495, percent: 1.14 },
    { value: 64095, percent: 0.855 },
    { value: 250095, percent: 0.57 },
    { percent: 0.057 }
  ],
  I: [
    { value: 7500, percent: 3.42 },
    { value: 17500, percent: 2.565 },
    { value: 30000, percent: 1.71 },
    { value: 45495, percent: 1.14 },
    { value: 64095, percent: 0.855 },
    { value: 250095, percent: 0.57 },
    { percent: 0.057 }
  ],
  J: [
    { value: 7500, percent: 4.56 },
    { value: 17500, percent: 2.85 },
    { value: 30000, percent: 2.28 },
    { value: 45495, percent: 1.71 },
    { value: 64095, percent: 1.14 },
    { value: 250095, percent: 0.57 },
    { percent: 0.057 }
  ],
  K: [
    { value: 7500, percent: 5.7 },
    { value: 17500, percent: 5.13 },
    { value: 30000, percent: 4.56 },
    { value: 45495, percent: 3.99 },
    { value: 64095, percent: 2.85 },
    { value: 250095, percent: 1.368 },
    { percent: 0.114 }
  ],
  L: [
    { value: 37000, percent: 0.57 },
    { value: 99000, percent: 0.399 },
    { value: 224000, percent: 0.285 },
    { value: 534000, percent: 0.171 },
    { value: 1784000, percent: 0.057 },
    { value: 3333500, percent: 0.0228 },
    { percent: 0.0114 }
  ],
  LBIS: [
    { value: 37000, percent: 0.57 },
    { value: 99000, percent: 0.399 },
    { value: 224000, percent: 0.1425 },
    { value: 534000, percent: 0.0855 },
    { value: 1784000, percent: 0.0285 },
    { value: 3333500, percent: 0.0114 },
    { percent: 0.0057 }
  ],
  M: [
    { value: 37000, percent: 0.855 },
    { value: 99000, percent: 0.57 },
    { value: 224000, percent: 0.399 },
    { value: 534000, percent: 0.228 },
    { value: 1784000, percent: 0.114 },
    { value: 3333500, percent: 0.0456 },
    { percent: 0.0228 }
  ],
  MBIS: [
    { value: 37000, percent: 0.855 },
    { value: 99000, percent: 0.57 },
    { value: 224000, percent: 0.1995 },
    { value: 534000, percent: 0.114 },
    { value: 1784000, percent: 0.057 },
    { value: 3333500, percent: 0.0228 },
    { percent: 0.0114 }
  ],
  SBGRL: [
    { value: 150000, percent: 3 },
    { value: 250000, percent: 9 },
    { value: 450000, percent: 18 },
    { percent: 27 }
  ],
  SBGAP: [
    { value: 150000, percent: 10 },
    { value: 250000, percent: 20 },
    { value: 450000, percent: 30 },
    { percent: 40 }
  ],
  SBRAP: [
    { percent: 7 }
  ],
  SBRRL: [
    { percent: 3 }
  ],
  SBWRL: [
    { value: 50000, percent: 2 },
    { value: 100000, percent: 5.3 },
    { value: 175000, percent: 6 },
    { value: 250000, percent: 12 },
    { value: 500000, percent: 24 },
    { percent: 30 }
  ],
  SVBAP: [
    { value: 150000, percent: 10 },
    { value: 175000, percent: 65 },
    { percent: 80 }
  ],
  SVBRL: [
    { value: 12500, percent: 1 },
    { value: 25000, percent: 2 },
    { value: 50000, percent: 3 },
    { value: 100000, percent: 5 },
    { value: 150000, percent: 8 },
    { value: 200000, percent: 14 },
    { value: 250000, percent: 18 },
    { value: 500000, percent: 24 },
    { percent: 30 }
  ],
  SVGAP: [
    { value: 150000, percent: 10 },
    { value: 250000, percent: 20 },
    { value: 450000, percent: 30 },
    { percent: 40 }
  ],
  SVGAPER: [
    { value: 150000, percent: 9 },
    { value: 250000, percent: 17 },
    { value: 450000, percent: 24 },
    { percent: 31 }
  ],
  SVGRL: [
    { value: 150000, percent: 3 },
    { value: 250000, percent: 9 },
    { value: 450000, percent: 18 },
    { percent: 27 }
  ],
  SVGRLER: [
    { value: 150000, percent: 3 },
    { value: 250000, percent: 6 },
    { value: 450000, percent: 12 },
    { percent: 18 }
  ],
  SVRRL: [
    { percent: 3 }
  ],
  SVRAP: [
    { percent: 7 }
  ],
  SWGAP: [
    { value: 150000, percent: 10 },
    { value: 250000, percent: 20 },
    { value: 450000, percent: 30 },
    { percent: 40 }
  ],
  SWGRL: [
    { value: 150000, percent: 3 },
    { value: 250000, percent: 9 },
    { value: 450000, percent: 18 },
    { percent: 27 }
  ],
  SWRAP: [
    { percent: 5.5 }
  ],
  SWRRL: [
    { percent: 3.3 }
  ]
};
