import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const mortgage = this.calculateMortgage(
        params.amount,
        params.openingCreditMortgageDelimiterAmount,
        params.openingCreditMortgageFixedMin,
        params.openingCreditMortgageFixedMax,
        params.openingCreditMortgageVariablePercentage
      );
      const registrationAmount = this.getRegistrationAmount(
        params.regHyp,
        params.amount,
        params.annex
      );
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale, params.feePercentage);
      const vatTaxableAmounts = [feeAmount, params.variousAmount, params.rog];
      const vatAmount = this.calculateVat(vatTaxableAmounts, params.vatPercentage);
      return [
        {
          labelLocale: 'label.reg',
          value: this.formatNumberToCurrency(registrationAmount)
        },
        {
          labelLocale: 'label.rog',
          value: this.formatNumberToCurrency(params.rog)
        },
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.mortgage',
          value: this.formatNumberToCurrency(mortgage)
        },
        {
          labelLocale: 'label.various',
          value: this.formatNumberToCurrency(params.variousAmount)
        },
        {
          ...this.getVatOutputLocale(vatTaxableAmounts),
          value: this.formatNumberToCurrency(vatAmount)
        },
        ...this.getProvisionOutputSectionConfigs(
          params.amount,
          [registrationAmount, feeAmount, params.variousAmount, vatAmount, params.rog, mortgage]
        )
      ];
    },

    calculateMortgage(amount, openingCreditMortgageDelimiterAmount, openingCreditMortgageFixedMin, openingCreditMortgageFixedMax, openingCreditMortgageVariablePercentage) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(
          this.getFixedMortgageCost(
            amount,
            openingCreditMortgageDelimiterAmount,
            openingCreditMortgageFixedMin,
            openingCreditMortgageFixedMax
          )
        ).plus(
          this.getVariableMortgageCost(
            amount,
            openingCreditMortgageVariablePercentage
          )
        )
      );
    },

    getFixedMortgageCost(amount, openingCreditMortgageDelimiterAmount, openingCreditMortgageFixedMin, openingCreditMortgageFixedMax) {
      if (amount <= openingCreditMortgageDelimiterAmount) {
        return openingCreditMortgageFixedMin;
      }
      return openingCreditMortgageFixedMax;
    },

    getVariableMortgageCost(amount, openingCreditMortgageVariablePercentage) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(amount)
        .times(openingCreditMortgageVariablePercentage)
        .dividedBy(100)
      );
    }
  }
};
