import Cookies from 'js-cookie';
import { SharedSettingsUtil } from 'notacalc-shared';

export default {
  ...SharedSettingsUtil,

  getStorredUserSetting(settingName) {
    return Cookies.get(settingName);
  },

  storeUserSetting(settingName, settingValue) {
    Cookies.set(settingName, settingValue, { expires: Infinity, path: '' });
  }
};
