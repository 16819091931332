import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';
import SharedScalesUtil from '../util/Scales';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale);
      const vatTaxableAmounts = [feeAmount, params.variousAmount, params.rog];
      const vatAmount = this.calculateVat(vatTaxableAmounts, params.vatPercentage);
      const registrationAmount = this.getRegistrationAmount(
        params.amount,
        params.donors,
        params.beneficiaries,
        params.registrationScale,
        params.annex
      );
      return [
        {
          labelLocale: 'label.reg',
          value: this.formatNumberToCurrency(registrationAmount)
        },
        {
          labelLocale: 'label.rog',
          value: this.formatNumberToCurrency(params.rog)
        },
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.mortgage',
          value: this.formatNumberToCurrency(params.mortgage)
        },
        {
          labelLocale: 'label.various',
          value: this.formatNumberToCurrency(params.variousAmount)
        },
        {
          ...this.getVatOutputLocale(vatTaxableAmounts),
          value: this.formatNumberToCurrency(vatAmount)
        },
        ...this.getProvisionOutputSectionConfigs(
          params.amount,
          [
            registrationAmount,
            feeAmount,
            params.variousAmount,
            vatAmount,
            params.rog,
            params.mortgage
          ]
        )
      ];
    },

    getRegistrationAmount(amount, donors, beneficiaries, registrationScale, annexAmount) {
      const amountDonorsBeneficiaries = SharedNumericUtil.decimalToNumber(
        new Decimal(amount)
        .dividedBy(new Decimal(donors).times(beneficiaries))
      );
      const scaledAmount = SharedScalesUtil.getTotalScaleFromAmount(
        registrationScale,
        amountDonorsBeneficiaries
      );
      return SharedNumericUtil.decimalToNumber(
        new Decimal(scaledAmount)
        .plus(annexAmount)
        .times(donors)
        .times(beneficiaries)
      );
    }
  }
};
