import SelectOptionKinds from '../store/SelectOptionKinds';

export default {
  getSelectOptions(selectOptionName) {
    return SelectOptionKinds[selectOptionName];
  },

  getSelectOptionsLocalized(selectOptionName, translateFn) {
    const selectOptionsRaw = this.getSelectOptions(selectOptionName);
    if (selectOptionsRaw) {
      return selectOptionsRaw.map((item) => {
        const itemLocalized = {
          value: item.value,
          name: translateFn(item.locale)
        }
        item.localized = itemLocalized.name;
        return itemLocalized;
      });
    }
  },

  getSelectOptionByField(selectOptionName, fieldName, fieldValue) {
    const selectOption = this.getSelectOptions(selectOptionName);
    if (!selectOption) {
      return null;
    }
    return selectOption.filter(element => element[fieldName] === fieldValue)[0];
  },

  selectOptionKindToArray(selectOptionName, fieldName) {
    const selectOption = this.getSelectOptions(selectOptionName);
    if (selectOption) {
      return selectOption.map(item => item[fieldName]);
    }
    return [];
  },

  localizeSelectOptions(localizeFn, localizeScope) {
    Object.keys(SelectOptionKinds).forEach((selectOptionName) => {
      const selectOption = SelectOptionKinds[selectOptionName];
      if (this.isSelectOptionLocalizeable(selectOption)) {
        this.localizeSelectOption(selectOption, localizeFn, localizeScope);
      }
    });
  },

  isSelectOptionLocalizeable(selectOption) {
    const firstOption = selectOption[0];
    if (firstOption && firstOption['locale']) {
      return true
    }
    return false;
  },

  localizeSelectOption(selectOption, localizeFn, localizeScope) {
    selectOption.forEach(option => {
      option.localized = localizeFn.call(localizeScope, option.locale);
    });
  }
};
