<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fpl-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="fpl-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fpl-paymentterm">{{ $t('label.paymentsterm') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="fpl-paymentterm" v-model="paymentTerm">
              <option
                v-for="option in this.getSelectOptions('periodMonthlyYearly')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fpl-interestperiod">{{ $t('label.intrestterm') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="fpl-interestperiod" v-model="interestPeriod">
              <option
                v-for="option in this.getSelectOptions('periodMonthlyYearly')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fpl-interestrate">{{ $t('label.intrestrate') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="fpl-interestrate"
            class="input"
            v-model="interestRate"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="fpl-nrofpayments">{{ $t('label.numberofpayments') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="fpl-nrofpayments"
            class="input"
            v-model="nrOfPayments"
            :options="cleaveMask.natural"
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedFinancingPersonalLoanCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedFinancingPersonalLoanCalculation],
  data() {
    return {
      amount: null,
      paymentTerm: 'monthly',
      interestPeriod: 'monthly',
      interestRate: null,
      nrOfPayments: 240
    };
  },

  computed: {
    isMonthlyInterestPeriod() {
      return this.interestPeriod === 'monthly';
    },
    isMonthlyPaymentTerm() {
      return this.paymentTerm === 'monthly';
    }
  },

  methods: {
    isInputFormValid() {
      const { amount, interestRate, nrOfPayments } = this;
      return amount && interestRate && nrOfPayments;
    },
    getCalculationParams() {
      const {
        amount,
        interestRate,
        isMonthlyInterestPeriod,
        isMonthlyPaymentTerm,
        nrOfPayments
      } = this;
      return {
        amount,
        interestRate,
        isMonthlyInterestPeriod,
        isMonthlyPaymentTerm,
        nrOfPayments
      };
    }
  }
};
</script>
