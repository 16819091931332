<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="spf-date">{{ $t('label.date') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <flat-pickr
            id="spf-date"
            v-model="date"
            :config="datePickerConfig"
            class="input"
            @on-change="onDatePickerChange"
          ></flat-pickr>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="spf-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="spf-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import { Dutch as nlFlatpickrLocale } from 'flatpickr/dist/l10n/nl';
import { French as frFlatpickrLocale } from 'flatpickr/dist/l10n/fr';
import enFlatpickrLocale from 'flatpickr/dist/l10n/default';
import { SharedEventBus, SharedSplittingOfPropertyTaxesCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedSplittingOfPropertyTaxesCalculation],
  components: {
    flatPickr
  },
  data() {
    return {
      date: new Date(),
      dateRaw: new Date(),
      amount: null,
      datePickerConfig: {
        dateFormat: 'd.m.Y',
        locale: null,
        locales: { nlFlatpickrLocale, frFlatpickrLocale, enFlatpickrLocale }
      }
    };
  },

  created() {
    this.updateDatePickerLocale();
    SharedEventBus.$on('localechange', this.updateDatePickerLocale);
  },

  beforeDestroy() {
    SharedEventBus.$off('localechange', this.updateDatePickerLocale);
  },

  methods: {
    onDatePickerChange(selectedDates) {
      [this.dateRaw] = selectedDates;
    },

    updateDatePickerLocale() {
      const localeCode = this.$i18n.locale;
      this.datePickerConfig.locale = this.datePickerConfig.locales[`${localeCode}FlatpickrLocale`];
    },

    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      const { amount, dateRaw } = this;
      return {
        amount,
        dateRaw
      };
    }
  }
};
</script>

<style lang="scss">
  @import '~flatpickr/dist/flatpickr.css';
</style>
