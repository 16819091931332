<template>
  <div class="content box home-box">
    <div class="columns is-mobile is-multiline is-centered">
      <article
        class="navigation-tile column has-text-centered is-one-third-mobile is-half-tablet
        is-one-third-desktop is-one-quarter-widescreen"
        v-for="(item, index) in Calculations"
        :key="index"
      >
        <router-link :to="item.path" tag="a" exact>
          <img :src="`${publicPath}assets/icons/${item.iconFile}.png`" />
        </router-link>
        <p class="subtitle is-size-6 has-text-weight-normal">{{ $t(item.locale) }}
        </p>
      </article>
    </div>
    <p class="has-text-centered mobile-section">
    <span class="mobile-section__title">{{ $t('label.mobileversion') }}:</span>
    <a class="button mobile-section__button mobile-section__button--android"
       href="https://play.google.com/store/apps/details?id=be.dataconsult.notacalc"
       target="_blank">
      <font-awesome-icon :icon="androidFaIcon" size="lg" class="mobile-section__item-fa" />Android
    </a>
    <a class="button mobile-section__button mobile-section__button--ios"
       href="https://apps.apple.com/us/app/notacalc/id1478739504?mt=8"
       target="_blank">
      <font-awesome-icon :icon="iosFaIcon" size="lg" class="mobile-section__item-fa" />iOS
    </a>
    </p>
    <p class="has-text-centered is-size-7">{{ $t('lastupdate') }}</p>
    <p class="has-text-centered copyright-section">
      © {{ (new Date()).getFullYear() }}
      <a href="//www.dataconsult.be"> {{ $t('label.dataconsult') }} </a>
    </p>
  </div>
</template>

<script>
import {
  SharedCalculationsStore,
  SharedSelectOptionKindsUtil,
  SharedEventBus
} from 'notacalc-shared';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import SettingsUtil from '@/util/Settings';

export default {
  components: {
    FontAwesomeIcon
  },

  data() {
    return {
      Calculations: SharedCalculationsStore,
      publicPath: process.env.BASE_URL,
      androidFaIcon: faAndroid,
      iosFaIcon: faApple
    };
  },

  methods: {
    setActiveLanguageFromPath() {
      const router = this.$router;
      const routerCurrentPath = router.history.current.path;
      if (routerCurrentPath !== '/') {
        const routeLanguage = this.$route.params.language;
        const isRouteLanguageValid = SharedSelectOptionKindsUtil.getSelectOptionByField(
          'languages',
          'value',
          routeLanguage
        );
        if (isRouteLanguageValid) {
          this.$i18n.locale = routeLanguage;
          SettingsUtil.setUserSetting('language', routeLanguage);
          SharedEventBus.$emit('localechange');
          router.push('/');
        }
      }
    }
  },

  watch: {
    $route: {
      handler: 'setActiveLanguageFromPath',
      immediate: true
    }
  }
};
</script>

<style lang="scss">
  @import '~bulma/sass/utilities/mixins';
  @include mobile {
    .home-box {
      padding-top: 30px;
    }
  }
  .navigation-tile {
    max-width: 150px;
    margin: 5px 10px;
  }
  @include widescreen {
    .home-box .columns {
      max-width: 800px;
      margin: 0 auto;
    }
  }
  .mobile-section {
    margin: 15px 0 25px !important;
    &__title {
      display: block;
      color: #868e96;
      padding-bottom: 5px;
    }
    &__button {
      transition: all 0.2s;
      font-size: 0.9rem;
      min-width: 100px;
      &:hover {
        color: #fff;
      }
    }
    &__button--android {
      color: #a4c639;
      border: 1px solid #a4c639;
      &:hover {
        border-color: #a4c639;
        background-color: #a4c639;
      }
    }
    &__button--ios {
      color: #007aff;
      border: 1px solid #007aff;
      margin-left: 15px;
      &:hover {
        border-color: #007aff;
        background-color: #007aff;
      }
    }
    &__item-fa {
      margin-right: 5px;
    }
  }
  .copyright-section {
    margin-top: 1rem;
  }
</style>
