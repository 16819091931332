<template>
  <div id="app">
    <navbar :show="true"></navbar>
    <main-wrapper></main-wrapper>
  </div>
</template>

<script>
import Navbar from '@/components/layout/Navbar.vue';
import MainWrapper from '@/components/layout/MainWrapper.vue';

export default {
  name: 'app',
  components: {
    Navbar,
    MainWrapper
  }
};
</script>

<style lang="scss">
  @import '~bulma';

  html {
    background-color: #f5f6fa;
    overflow: hidden;
  }
  html, body, #app {
    height: 100%;
    min-height: 100%;
    overflow-x: auto;
  }
  .field-label {
    @include tablet() {
      flex-grow: 2;
    }
  }
  .box {
    border-radius: 0;
    box-shadow: 0 1px 4px 0 rgba(0,0,0,.14)
  }
  .vue-notification {
    font-size: inherit;
  }
</style>
