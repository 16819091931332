<template>
  <div class="content box input-box">
    <h1>{{ $t(calculationItem.locale) }}</h1>
    <div class="columns">
      <form class="column is-three-quarters-tablet is-three-fifths-widescreen">
        <component :is="calculationItem.inputComponent" :calculationItem="calculationItem">
          <div class="field is-horizontal calculation-buttons" slot="actions">
            <div class="field-label is-normal">
            </div>
            <div class="field-body">
              <div class="field">
                <button class="button is-link" @click.prevent="doCalculate">
                  {{ $t("action.submit") }}
                </button>
                <button class="button calculation-cancel-button" @click.prevent="doCancel">
                  {{ $t("action.cancel") }}
                </button>
              </div>
            </div>
          </div>
        </component>
      </form>
      <div class="column has-text-right">
        <img :src="`${publicPath}assets/icons/${calculationItem.iconFile}.png`" />
      </div>
    </div>
  </div>
</template>

<script>
import { SharedEventBus } from 'notacalc-shared';
import {
  FinancingPersonalLoan,
  Donation,
  Exchange,
  Emancipation,
  OpeningCredit,
  Distribution,
  Loan,
  Sale,
  CompanyFormation,
  Fees,
  SplittingOfPropertyTaxes,
  ValueOfUsufruct
} from '@/components/view/input';

export default {
  props: ['calculationItem'],
  data() {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  components: {
    FinancingPersonalLoan,
    Donation,
    Exchange,
    Emancipation,
    OpeningCredit,
    Distribution,
    Loan,
    Sale,
    CompanyFormation,
    Fees,
    SplittingOfPropertyTaxes,
    ValueOfUsufruct
  },
  methods: {
    doCalculate() {
      SharedEventBus.$emit('calculate');
    },
    doCancel() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
  @import '~bulma/sass/utilities/mixins';
  .input-box {
    position: relative;
    @include desktop {
      img {
        max-height: 200px;
      }
    }
    @include widescreen {
      img {
        max-height: 200px;
        margin-top: -52px;
      }
    }
    @include touch {
      img {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 52px;
      }
    }
  }
  .navigation-tile {
    max-width: 150px;
    margin: 5px 10px;
  }
  .calculation-buttons {
    margin-top: 20px;
  }
  .calculation-cancel-button {
    margin-left: 10px;
  }
</style>
