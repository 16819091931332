import AppSettings from '../store/AppSettings';
import UserSettings from '../store/UserSettings';

export default {
  getAppSetting(settingName) {
    return this.getAppSettings()[settingName];
  },

  getAppSettings() {
    return AppSettings;
  },

  getUserSetting(settingName) {
    return this.getStorredUserSetting(settingName) || UserSettings[settingName];
  },

  /**
   * The method should be overriden in the web and mobile apps separately, depending on the way
   * the user settings are stored
   */
  getStorredUserSetting(settingName) {},

  getUserSettings() {
    const userSettings = {};
    Object.keys(UserSettings).forEach((settingName) => {
      userSettings[settingName] = this.getUserSetting(settingName);
    });
    return userSettings;
  },

  setUserSetting(settingName, settingValue) {
    const userSettings = this.getUserSettings();
    if (userSettings.hasOwnProperty(settingName)) {
      userSettings[settingName] = settingValue;
      this.storeUserSetting(settingName, settingValue);
    }
  },

  /**
   * The method should be overriden in the web and mobile apps separately, depending on the way
   * the user settings are stored
   */
  storeUserSetting(settingName, settingValue) {},

  setUserSettings(settingsData) {
    Object.keys(settingsData).forEach((settingName) => {
      this.setUserSetting(settingName, settingsData[settingName]);
    });
  }
};
