<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-reghyp">{{ $t('label.reg') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="cf-reghyp" v-model="regHyp">
              <option
                v-for="option in this.getSelectOptions('regHypCompanyFormation')"
                :value="option.value"
                :key="option.value"
              >{{ option.titleAdditional }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-rog">{{ $t('label.rog') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="cf-rog" v-model="rog">
              <option
                v-for="option in this.getSelectOptions('rogKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-feepercentage">{{ $t('label.fee') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="cf-feepercentage" v-model="feePercentage">
              <option
                v-for="option in this.getSelectOptions('fees10050')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-type">{{ $t('label.typeofcompany') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="cf-type" v-model="type">
              <option
                v-for="option in this.getSelectOptions('companyFormationType')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-annex">{{ $t('label.annex') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="cf-annex" v-model="annex">
              <option
                v-for="option in this.getSelectOptions('annexKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-various">{{ $t('label.various') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="cf-various"
            class="input"
            v-model="various"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="cf-amount">{{ $t('label.capital') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="cf-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedCompanyFormationCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedCompanyFormationCalculation],
  created() {
    this.amendTitleToRegHypSelection(this.additionalRegAmount);
  },
  data() {
    return {
      additionalRegAmount: this.getAppSetting('additionalCompanyFormationRegAmount'),
      regHyp: 0,
      feePercentage: 100,
      type: 'bvOthers',
      rog: 95,
      various: this.getDefaultVariousAmount(),
      amount: null,
      annex: 0
    };
  },
  computed: {
    feeScale() {
      return this.type === 'bvOthers' ? 'L' : 'M';
    }
  },
  methods: {
    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      const {
        amount,
        regHyp,
        feeScale,
        feePercentage,
        rog,
        additionalRegAmount,
        annex
      } = this;
      const { vatPercentage, companyFormationMortgage } = this.getAppSettings();
      const variousAmount = this.getNumericVariousAmount();
      return {
        amount,
        regHyp,
        rog,
        feePercentage,
        feeScale,
        additionalRegAmount,
        vatPercentage,
        mortgage: companyFormationMortgage,
        variousAmount,
        annex
      };
    }
  }
};
</script>
