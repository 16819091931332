import { Decimal } from 'decimal.js';
import EventBus from '../eventBus';
import NumericUtil from '../util/Numeric';
import SettingsUtil from '../util/Settings';
import SelectOptionKindsUtil from '../util/SelectOptionKinds';
import CommonCalculationsUtil from '../util/CommonCalculations';

export default {
  created() {
    EventBus.$on('calculate', this.onCalculate);
  },

  beforeDestroy() {
    EventBus.$off('calculate', this.onCalculate);
  },

  methods: {
    ...NumericUtil,
    ...SettingsUtil,
    ...SelectOptionKindsUtil,
    ...CommonCalculationsUtil,

    onCalculate() {
      if (this.isInputFormValid()) {
        this.emitCalculate(
          this.getOutputResults()
        );
      }
      else {
        this.emitFormInvalid();
      }
    },

    isInputFormValid() {
      return false;
    },

    getOutputResults() {
      return [];
    },

    emitCalculate(calculatedResultsArray) {
      EventBus.$emit('calculated', calculatedResultsArray);
    },

    emitFormInvalid() {
      EventBus.$emit('forminvalid');
    },

    getDefaultVariousAmount() {
      return parseFloat(this.getUserSetting('standardVariousCost')) || '';
    },

    getProvisionOutputSectionConfigs(baseAmount, amountsArr) {
      const provisionAmount = this.sumNumbersArray(amountsArr);
      const provisionPercentageDecimal = new Decimal(provisionAmount)
        .dividedBy(baseAmount)
        .times(100);
      return [
        {
          labelLocale: 'label.provision',
          value: this.formatNumberToCurrency(provisionAmount)
        },
        {
          labelLocale: 'label.provision',
          value: `${this.formatDecimalToCurrency(provisionPercentageDecimal)}%`
        }
      ];
    },

    getVatOutputLocale(amountsArr) {
      const taxableAmountFormatted = this.formatNumberToCurrency(
        this.sumNumbersArray(amountsArr)
      );
      return {
        labelLocale: 'label.vatdetailed',
        labelLocaleParams: {
          details: `${taxableAmountFormatted} x ${this.getAppSetting('vatPercentage')}`
        }
      };
    }
  }
};
