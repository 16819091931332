import { SharedInputMixin, SharedCleaveMasksStore } from 'notacalc-shared';
import SettingsUtil from '@/util/Settings';

export default {
  mixins: [SharedInputMixin],
  props: ['calculationItem'],
  data() {
    return {
      cleaveMask: SharedCleaveMasksStore
    };
  },
  methods: {
    ...SettingsUtil,

    getNumericVariousAmount() {
      return parseFloat(this.various) || 0;
    }
  }
};
