<template>
  <section class="app-navbar animated" :class="{ slideInDown: show, slideOutDown: !show }">
      <nav class="navbar is-fixed-top" role="navigation">
        <div class="navbar-brand">
          <router-link to="/" exact tag="a" class="navbar-item">
            <img class="navbar-logo-img" src="@/assets/logo.png" />
            &nbsp;
            Notacalc
          </router-link>
          <a role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            :class="{ 'is-active': isBurgerExpanded }"
            @click="onBurgerClick"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': isBurgerExpanded }">
          <div class="navbar-end">
            <div class="navbar-item">
              <div class="field is-grouped">
                <p class="control">
                  <router-link to="/settings" exact tag="a" class="button"
                    @click.native="onSettingsClick"
                  >
                    <span>{{ $t('label.settings') }}</span>
                  </router-link>
                </p>
                <div class="control field">
                  <div class="field-body">
                    <div class="field">
                      <div class="select is-fullwidth">
                        <select v-model="language">
                          <option
                            v-for="option in this.getSelectOptions('languages')"
                            :value="option.value"
                            :key="option.value"
                          >{{ $t(option.locale) }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <p class="control tooltip">
                  <router-link to="/about" exact tag="a" class="button about-button"
                    @click.native="onSettingsClick"
                  >
                    <font-awesome-icon :icon="informationFaIcon" size="lg" />
                  </router-link>
                  <span class="tooltiptext">{{ $t('label.about') }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </nav>
  </section>
</template>

<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SharedSelectOptionKindsUtil, SharedEventBus } from 'notacalc-shared';
import SettingsUtil from '@/util/Settings';

export default {
  components: {
    FontAwesomeIcon
  },
  props: {
    show: Boolean
  },
  data() {
    return {
      language: this.$i18n.locale,
      isBurgerExpanded: false,
      informationFaIcon: faInfoCircle
    };
  },
  methods: {
    ...SharedSelectOptionKindsUtil,
    onBurgerClick() {
      this.isBurgerExpanded = !this.isBurgerExpanded;
    },
    onSettingsClick() {
      this.isBurgerExpanded = false;
    },
    updateSelectedLanguage() {
      this.language = this.$i18n.locale;
    }
  },
  created() {
    SharedEventBus.$on('localechange', this.updateSelectedLanguage);
  },
  watch: {
    language(value) {
      this.$i18n.locale = value;
      SettingsUtil.setUserSetting('language', value);
      SharedEventBus.$emit('localechange');
      this.isBurgerExpanded = false;
    }
  }
};
</script>

<style lang="scss">
  .app-navbar {
    min-width: 100%;
    z-index: 1024;
    position: relative;
    .navbar {
      box-shadow: 0 2px 3px rgba(17, 17, 17, 0.1), 0 0 0 1px rgba(17, 17, 17, 0.1);
      padding: 0 10px;
    }
    .navbar-logo-img {
      max-height: 2rem;
      margin-right: 8px;
    }
    .navbar.is-fixed-top .navbar-menu {
      overflow: visible
    }
    .about-button {
      color: #327bff;
    }
  }
  .tooltip {
    position: relative;
    .tooltiptext {
      visibility: hidden;
      width: 120px;
      background-color: #868e96;
      color: #fff;
      text-align: center;
      padding: 5px 0;
      border-radius: 5px;
      position: absolute;
      z-index: 1;
    }
    &:hover .tooltiptext {
      visibility: visible;
      top: 0;
      right: 105%;
    }
  }
</style>
