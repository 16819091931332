import Home from '@/components/view/Home.vue';
import About from '@/components/view/About.vue';
import PrivacyPolicy from '@/components/view/PrivacyPolicy.vue';
import Settings from '@/components/view/Settings.vue';
import Calculation from '@/components/view/Calculation.vue';

export default [
  { path: '/', component: Home },
  { path: '/language=:language', component: Home },
  { path: '/about', component: About },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/settings', component: Settings },
  { path: '/calculate/:kind', component: Calculation }
];
