export default {
  numeral: {
    numeral: true,
    delimiter: '.',
    numeralDecimalMark: ',',
    numeralThousandsGroupStyle: 'thousand'
  },
  natural: {
    numeral: true,
    delimiter: '.',
    numeralDecimalMark: ',',
    numeralThousandsGroupStyle: 'thousand',
    numeralDecimalScale: 0,
    numeralPositiveOnly: true
  }
};
