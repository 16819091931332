<template>
  <div class="content box output-box">
    <h1>{{ $t('results') }}</h1>
    <div class="column table-container is-two-quarters-desktop is-two-fifths-widescreen">
      <table class="table">
        <tbody>
          <tr v-for="(result, index) in results" :key="index">
            <td v-bind:class="{ 'has-text-weight-semibold': !result.value }">
              {{ $t(result.labelLocale, result.labelLocaleParams)
              }}<span v-if="result.value">:</span>
            </td>
            <td class="has-text-right">{{ result.value }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { SharedEventBus } from 'notacalc-shared';

export default {
  props: ['calculationItem'],
  data() {
    return {
      results: null
    };
  },
  created() {
    SharedEventBus.$on('calculated', this.onCalculated);
  },
  beforeDestroy() {
    SharedEventBus.$off('calculated', this.onCalculated);
  },
  methods: {
    onCalculated(results) {
      this.results = results;
      setTimeout(() => {
        this.$el.scrollIntoView();
      }, 500);
    }
  }
};
</script>

<style lang="scss">
.output-box {
  .table-container {
    padding: 0;
  }
}
</style>
