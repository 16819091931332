<template>
  <div class="content box">
    <h2>{{ $t('label.settings') }}</h2>
    <form class="column is-three-quarters-desktop is-three-fifths-widescreen">
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('label.defreg') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="select is-fullwidth">
              <select v-model="standardRegHyp">
                <option
                  v-for="option in this.getSelectOptions('regHypSetting')"
                  :value="option.value"
                  :key="option.value"
                >{{ option.title }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('label.defvariouscost') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <cleave
              type="tel"
              class="input"
              v-model="standardVariousCost"
              :options="cleaveMask.numeral"
            ></cleave>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
          <label class="label">{{ $t('label.region') }}</label>
        </div>
        <div class="field-body">
          <div class="field">
            <div class="select is-fullwidth">
              <select v-model="region">
                <option
                  v-for="option in this.getSelectOptions('regions')"
                  :value="option.value"
                  :key="option.value"
                >{{ $t(option.locale) }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="field is-horizontal">
        <div class="field-label is-normal">
        </div>
        <div class="field-body">
          <div class="field">
            <button class="button is-link" @click.prevent="doSaveSettings">
              {{ $t("action.save") }}
            </button>
            <button class="button settings-cancel-button" @click.prevent="doCancel">
              {{ $t("action.cancel") }}
            </button>
          </div>
        </div>
      </div>
    </form>
    <notifications
      group="settings"
      position="bottom right"
    />
  </div>
</template>

<script>
import { SharedCleaveMasksStore, SharedSelectOptionKindsUtil } from 'notacalc-shared';
import SettingsUtil from '@/util/Settings';

export default {
  data() {
    return {
      ...SettingsUtil.getUserSettings(),
      cleaveMask: SharedCleaveMasksStore
    };
  },
  methods: {
    ...SharedSelectOptionKindsUtil,
    doSaveSettings() {
      SettingsUtil.setUserSettings({
        standardRegHyp: this.standardRegHyp,
        region: this.region,
        standardVariousCost: this.standardVariousCost
      });
      this.$notify({
        type: 'success',
        group: 'settings',
        text: this.$t('message.settingssaved')
      });
    },
    doCancel() {
      this.$router.push('/');
    }
  }
};
</script>

<style lang="scss">
  .settings-cancel-button {
    margin-left: 10px;
  }
</style>
