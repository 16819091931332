<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-reghyp">{{ $t('label.reg') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="lo-reghyp" v-model="regHyp">
              <option
                v-for="option in this.getSelectOptions('regHypLoan')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-rog">{{ $t('label.rog') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="lo-rog" v-model="rog">
              <option
                v-for="option in this.getSelectOptions('rogKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-feepercentage">{{ $t('label.fee') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="lo-feepercentage" v-model="feePercentage">
              <option
                v-for="option in this.getSelectOptions('fees10050')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-annex">{{ $t('label.annex') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="lo-annex" v-model="annex">
              <option
                v-for="option in this.getSelectOptions('annexKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-various">{{ $t('label.various') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="lo-various"
            class="input"
            v-model="various"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="lo-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="lo-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedLoanCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedLoanCalculation],
  data() {
    return {
      feeScale: 'F',
      regHyp: 1,
      feePercentage: 100,
      rog: 50,
      various: this.getDefaultVariousAmount(),
      amount: null,
      annex: 0
    };
  },

  methods: {
    isInputFormValid() {
      return this.amount;
    },

    getCalculationParams() {
      const {
        amount,
        regHyp,
        feeScale,
        feePercentage,
        rog,
        annex
      } = this;
      const {
        loanMortgageDelimiterAmount,
        loanMortgageFixedMin,
        loanMortgageFixedMax,
        loanMortgageVariablePercentage,
        vatPercentage
      } = this.getAppSettings();
      const variousAmount = this.getNumericVariousAmount();
      return {
        amount,
        regHyp,
        feeScale,
        feePercentage,
        rog,
        variousAmount,
        loanMortgageDelimiterAmount,
        loanMortgageFixedMin,
        loanMortgageFixedMax,
        loanMortgageVariablePercentage,
        vatPercentage,
        annex
      };
    }
  }
};
</script>
