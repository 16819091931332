<template>
  <section>
    <div class="field is-horizontal">
      <div class="field-label is-normal"></div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select v-model="partiesRelationKind">
              <option
                v-for="option in this.getSelectOptions('donationPartiesRelations')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal"></div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select v-model="donationKind">
              <option
                v-for="option in this.getSelectOptions('donationKinds')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal"></div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select v-model="goodTypeDonatorRegionKind">
              <option
                v-for="option in this.getSelectOptions('donationTypesAndRegions')"
                :value="option.value"
                :key="option.value"
              >{{ $t(option.locale) }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-rog">{{ $t('label.rog') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="dn-rog" v-model="rog">
              <option
                v-for="option in this.getSelectOptions('rogKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-annex">{{ $t('label.annex') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="select is-fullwidth">
            <select id="dn-annex" v-model="annex">
              <option
                v-for="option in this.getSelectOptions('annexKinds')"
                :value="option.value"
                :key="option.value"
              >{{ option.title }}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-various">{{ $t('label.various') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="dn-various"
            class="input"
            v-model="various"
            :options="cleaveMask.numeral"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal" v-if="isRenovationApplicable">
      <div class="field-label is-normal">
        <label class="label" for="dn-withrenovation">{{ $t('label.withRenovation') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <input
            type="checkbox"
            id="dn-withrenovation"
            style="vertical-align: bottom;"
            v-model="withRenovation"
          />
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-amount">{{ $t('label.amount') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="dn-amount"
            class="input"
            v-model="amount"
            :options="cleaveMask.numeral"
            v-focus
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-donors">{{ $t('label.donors') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="dn-donors"
            class="input"
            v-model="donors"
            :options="cleaveMask.natural"
          ></cleave>
        </div>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <label class="label" for="dn-beneficiaries">{{ $t('label.beneficiaries') }}</label>
      </div>
      <div class="field-body">
        <div class="field">
          <cleave
            type="tel"
            id="dn-beneficiaries"
            class="input"
            v-model="beneficiaries"
            :options="cleaveMask.natural"
          ></cleave>
        </div>
      </div>
    </div>
    <slot name="actions"></slot>
  </section>
</template>

<script>
import { SharedDonationCalculation } from 'notacalc-shared';
import InputMixin from '../mixin/Input';

export default {
  mixins: [InputMixin, SharedDonationCalculation],
  data() {
    return {
      partiesRelationKind: 'RL',
      donationKind: 'makingForward',
      goodTypeDonatorRegionKind: 'BG',
      rog: 7.5,
      withRenovation: false,
      various: this.getDefaultVariousAmount(),
      amount: null,
      donors: 1,
      beneficiaries: 1,
      annex: 0
    };
  },
  computed: {
    registrationScale() {
      const { partiesRelationKind, goodTypeDonatorRegionKind } = this;
      let scaleName = `S${goodTypeDonatorRegionKind}${partiesRelationKind}`;
      if (this.isRenovationApplicable && this.withRenovation) {
        scaleName += 'ER';
      }
      return scaleName;
    },

    isRenovationApplicable() {
      const { goodTypeDonatorRegionKind } = this;
      return goodTypeDonatorRegionKind === 'VG';
    },

    feeScale() {
      return this.donationKind === 'makingForward' ? 'H' : 'F';
    },

    mortgage() {
      const { goodTypeDonatorRegionKind } = this;
      const { donationMortgage } = this.getAppSettings();
      const isGoodTypeMovableProperty = goodTypeDonatorRegionKind[1] === 'R';
      return isGoodTypeMovableProperty ? 0 : donationMortgage;
    }
  },
  methods: {
    isInputFormValid() {
      return parseInt(this.amount, 10)
        && parseInt(this.donors, 10)
        && parseInt(this.beneficiaries, 10);
    },

    getCalculationParams() {
      const {
        amount,
        rog,
        donors,
        beneficiaries,
        registrationScale,
        feeScale,
        mortgage,
        annex
      } = this;
      const { vatPercentage } = this.getAppSettings();
      const variousAmount = this.getNumericVariousAmount();
      return {
        amount,
        rog,
        donors,
        beneficiaries,
        registrationScale,
        feeScale,
        vatPercentage,
        mortgage,
        variousAmount,
        annex
      };
    }
  }
};
</script>
