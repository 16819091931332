export default [
  {
    locale: 'calculation.finance',
    path: '/calculate/financing_personal_loan',
    inputComponent: 'FinancingPersonalLoan',
    iconFile: 'finance'
  },
  {
    locale: 'calculation.donation',
    path: '/calculate/donation',
    inputComponent: 'Donation',
    iconFile: 'donation'
  },
  {
    locale: 'calculation.exchange',
    path: '/calculate/exchange',
    inputComponent: 'Exchange',
    iconFile: 'exchange'
  },
  {
    locale: 'calculation.emancipation',
    path: '/calculate/emancipation',
    inputComponent: 'Emancipation',
    iconFile: 'emancipation'
  },
  {
    locale: 'calculation.credit',
    path: '/calculate/opening_credit',
    inputComponent: 'OpeningCredit',
    iconFile: 'credit'
  },
  {
    locale: 'calculation.distribution',
    path: '/calculate/distribution',
    inputComponent: 'Distribution',
    iconFile: 'distribution'
  },
  {
    locale: 'calculation.loan',
    path: '/calculate/loan',
    inputComponent: 'Loan',
    iconFile: 'loan'
  },
  {
    locale: 'calculation.sale',
    path: '/calculate/sale',
    inputComponent: 'Sale',
    iconFile: 'sale'
  },
  {
    locale: 'calculation.formation',
    path: '/calculate/company_formation',
    inputComponent: 'CompanyFormation',
    iconFile: 'formation'
  },
  {
    locale: 'calculation.fees',
    path: '/calculate/fees',
    inputComponent: 'Fees',
    iconFile: 'fees'
  },
  {
    locale: 'calculation.split',
    path: '/calculate/splitting_of_property_taxes',
    inputComponent: 'SplittingOfPropertyTaxes',
    iconFile: 'split'
  },
  {
    locale: 'calculation.usufruct',
    path: '/calculate/value_of_usufruct',
    inputComponent: 'ValueOfUsufruct',
    iconFile: 'usufruct'
  }
];
