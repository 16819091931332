import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const mortgage = this.calculateMortgage(
        params.amount,
        params.loanMortgageDelimiterAmount,
        params.loanMortgageFixedMin,
        params.loanMortgageFixedMax,
        params.loanMortgageVariablePercentage
      );
      const registrationAmount = this.getRegistrationAmount(
        params.regHyp,
        params.amount,
        params.annex
      );
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale, params.feePercentage);
      const vatTaxableAmounts = [feeAmount, params.variousAmount, params.rog];
      const vatAmount = this.calculateVat(vatTaxableAmounts, params.vatPercentage);
      return [
        {
          labelLocale: 'label.reg',
          value: this.formatNumberToCurrency(registrationAmount)
        },
        {
          labelLocale: 'label.rog',
          value: this.formatNumberToCurrency(params.rog)
        },
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.mortgage',
          value: this.formatNumberToCurrency(mortgage)
        },
        {
          labelLocale: 'label.various',
          value: this.formatNumberToCurrency(params.variousAmount)
        },
        {
          ...this.getVatOutputLocale(vatTaxableAmounts),
          value: this.formatNumberToCurrency(vatAmount)
        },
        ...this.getProvisionOutputSectionConfigs(
          params.amount,
          [registrationAmount, feeAmount, params.variousAmount, vatAmount, params.rog, mortgage]
        )
      ];
    },

    calculateMortgage(amount, loanMortgageDelimiterAmount, loanMortgageFixedMin, loanMortgageFixedMax, loanMortgageVariablePercentage) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(
          this.getFixedMortgageCost(
            amount,
            loanMortgageDelimiterAmount,
            loanMortgageFixedMin,
            loanMortgageFixedMax
          )
        ).plus(
          this.getVariableMortgageCost(
            amount,
            loanMortgageVariablePercentage
          )
        )
      );
    },

    getFixedMortgageCost(amount, loanMortgageDelimiterAmount, loanMortgageFixedMin, loanMortgageFixedMax) {
      if (amount <= loanMortgageDelimiterAmount) {
        return loanMortgageFixedMin;
      }
      return loanMortgageFixedMax;
    },

    getVariableMortgageCost(amount, loanMortgageVariablePercentage) {
      return SharedNumericUtil.decimalToNumber(
        new Decimal(amount)
        .times(loanMortgageVariablePercentage)
        .dividedBy(100)
      );
    }
  }
};
