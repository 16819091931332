import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import CleaveComponent from 'vue-cleave-component';
import Notifications from 'vue-notification';
import { SharedTranslationsStore } from 'notacalc-shared';
import App from '@/App.vue';
import routes from '@/router';
import SettingsUtil from '@/util/Settings';

Vue.use(VueI18n);
Vue.use(VueRouter);
Vue.use(CleaveComponent);
Vue.use(Notifications);

Vue.directive('focus', {
  inserted(el) {
    el.focus();
  }
});

// Vue instance options
const i18nLocale = SettingsUtil.getUserSetting('language');
const i18n = new VueI18n({
  locale: i18nLocale,
  messages: SharedTranslationsStore
});
const router = new VueRouter({
  routes
});

// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  i18n,
  render: h => h(App)
});
