import Decimal from 'decimal.js';
import ScalesUtil from '../util/Scales';
import NumericUtil from '../util/Numeric';

export default {
  getRegistrationAmount(percentage, amount, additionalAmount, abatement) {
    let registrationAmount = this.computeRegistrationAmount(
      percentage,
      amount,
      additionalAmount,
      abatement
    );
    return registrationAmount;
  },

  computeRegistrationAmount(percentage, amount, additionalAmount = 0, abatement = 0) {
    return NumericUtil.decimalToNumber(new Decimal(amount)
      .minus(abatement)
      .times(percentage)
      .dividedBy(100)
      .plus(additionalAmount)
    );
  },

  getFeeAmount(amount, scaleName, feePercentage) {
    let feeAmount = this.computeFeeAmount(amount, scaleName, feePercentage);
    const minimumFeeAmount = this.getMinimumFeeAmount();
    if (feeAmount < minimumFeeAmount) {
      feeAmount = minimumFeeAmount;
    }
    return feeAmount;
  },

  computeFeeAmount(amount, scaleName, feePercentage = 100) {
    const feeAmount = ScalesUtil.getTotalScaleFromAmount(scaleName, amount);
    return NumericUtil.decimalToNumber(new Decimal(feeAmount)
      .times(feePercentage)
      .dividedBy(100));
  },

  getMinimumFeeAmount() {
    return this.getAppSetting('minFeeAmount');
  },

  calculateVat(amountsArr, vatPercentage) {
    return NumericUtil.decimalToNumber(
      new Decimal(NumericUtil.sumNumbersArray(amountsArr))
        .times(vatPercentage)
        .dividedBy(100)
    );
  }
};
