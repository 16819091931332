import { Decimal } from 'decimal.js';
import SharedNumericUtil from '../util/Numeric';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const ageCoefficient = this.getSelectOptionByField(
        'usufructAgeIntervals',
        'id',
        params.ageInterval
      ).coefficient;
      return [
        {
          labelLocale: 'usufruct',
          value: this.formatDecimalToCurrency(
            this.getUsufructAmount(params.amount, ageCoefficient)
          )
        },
        {
          labelLocale: 'usufruct',
          value: `${this.formatDecimalToCurrency(
            this.getUsufructPercentage(params.amount, ageCoefficient)
          )}%`
        },
        {
          labelLocale: 'bareproperty',
          value: this.formatDecimalToCurrency(
            this.getBareOwnershipAmount(params.amount, ageCoefficient)
          )
        }
      ];
    },

    getUsufructAmount(amount, ageCoefficient) {
      return new Decimal(amount)
        .dividedBy(100)
        .times(4)
        .times(ageCoefficient);
    },

    getUsufructPercentage(amount, ageCoefficient) {
      return this.getUsufructAmount(amount, ageCoefficient)
        .dividedBy(amount)
        .times(100);
    },

    getBareOwnershipAmount(amount, ageCoefficient) {
      return new Decimal(amount)
        .minus(this.getUsufructAmount(amount, ageCoefficient));
    }
  }
};
