const donationTypesAndRegions = {
  BG: '@:selection.donationGoodTypes.ordinary (@:region.brussels)',
  BR: '@:selection.donationGoodTypes.movable (@:region.brussels)',
  WG: '@:selection.donationGoodTypes.ordinary (@:region.wallonia)',
  WR: '@:selection.donationGoodTypes.movable (@:region.wallonia)',
  VG: '@:selection.donationGoodTypes.ordinary (@:region.flanders)',
  VB: '@:selection.donationGoodTypes.constructionplot (@:region.flanders)',
  VR: '@:selection.donationGoodTypes.movable (@:region.flanders)'
};

export default {
  en: {
    language: {
      ned: 'Nederlands',
      fra: 'Frans',
      eng: 'English'
    },
    calculation: {
      finance: 'Financing/ Personal Loan',
      donation: 'Donation',
      exchange: 'Exchange',
      emancipation: 'Emancipation',
      credit: 'Opening Credit',
      distribution: 'Distribution',
      loan: 'Loan',
      sale: 'Sale',
      formation: 'Company formation',
      fees: 'Fees',
      split: 'Splitting of property taxes',
      usufruct: 'Value of usufruct'
    },
    label: {
      dataconsult: 'Data Consult BVBA',
      amount: 'Amount',
      paymentsterm: 'Payment term',
      intrestrate: 'Intrest rate',
      intrestterm: 'Intrest period',
      numberofpayments: '# of payments',
      reg: 'Reg.',
      rog: 'Rog',
      regrog: 'Reg. & Rog',
      fee: 'Fees',
      abatement: 'Abatement',
      various: 'Various',
      provision: 'Provision',
      typeofcompany: 'Company type',
      capital: 'Capital',
      scale: 'Scale',
      age: 'Age',
      propertyworth: 'Full property',
      language: 'Language',
      region: 'Region',
      date: 'Date',
      vat: 'VAT',
      vatdetailed: '@:label.vat ({details})',
      downpayment: 'Fixed installment',
      yearlyinterestrate: 'Yearly interest rate',
      monthlyinterestrate: 'Monthly interest rate',
      settings: 'Settings',
      about: 'About',
      defreg: 'Standard registration',
      defvariouscost: 'Standard various costs',
      phone: 'Phone',
      donors: 'Donors',
      beneficiaries: 'Beneficiaries',
      withRenovation: 'Renovation',
      mortgage: 'Mortgage',
      home: 'Home',
      mobileversion: 'Mobile version',
      annex: 'Annex'
    },
    region: {
      flanders: 'Flanders',
      wallonia: 'Wallonia',
      brussels: 'Brussels'
    },
    action: {
      ok: 'OK',
      cancel: 'Cancel',
      save: 'Save',
      close: 'Close',
      submit: 'Submit',
      calculate: 'Calculate'
    },
    selection: {
      periodMonthlyYearly: {
        monthly: 'Monthly',
        yearly: 'Yearly'
      },
      donationPartiesRelations: {
        straightline: 'Lineal and among partners',
        others: 'Between other persons'
      },
      donationKinds: {
        makingforward: 'By making forward',
        advance: 'Advance on inheritance'
      },
      donationGoodTypes: {
        ordinary: 'Ordinary goods',
        constructionplot: 'Construction plot',
        movable: 'Movable property'
      },
      donationTypesAndRegions,
      distributionAbatement: {
        actualDistribution: 'Actual distribution',
        toCoowner: 'To co-owner'
      },
      companyFormationType: {
        bvOthers: 'B.V. and others',
        nv: 'N.V.'
      }
    },
    message: {
      fieldrequired: 'This field is required',
      requiredfields: 'Please fill in all required fields',
      settingssaved: 'Your settings have been saved!'
    },
    lastupdate: 'Last update: 21/10/2022',
    results: 'Results',
    error: 'Error',
    savefailed: 'Saving settings failed.',
    invalidamount: 'Invalid amount.',
    expired: 'Expired',
    remaining: 'Remaining',
    days: 'Days',
    selectdate: 'Select date',
    abandonment: 'Abandonment',
    options: 'Options',
    usufruct: 'Usufruct',
    bareproperty: 'Bare ownership'
  },
  nl: {
    language: {
      ned: 'Nederlands',
      fra: 'Frans',
      eng: 'Engels'
    },
    calculation: {
      finance: 'Financiering / Pers. lening',
      donation: 'Schenking',
      exchange: 'Ruil',
      emancipation: 'Opheffing (Handlichting)',
      credit: 'Kredietopening',
      distribution: 'Verdeling',
      loan: 'Lening',
      sale: 'Verkoop',
      formation: 'Oprichting vennootschap',
      fees: 'Erelonen',
      split: 'Splitsing onroerende voorh.',
      usufruct: 'Waarde vruchtgebruik'
    },
    label: {
      dataconsult: 'Data Consult BVBA',
      amount: 'Bedrag',
      paymentsterm: 'Betalingstermijn',
      intrestrate: 'Intrestvoet',
      intrestterm: 'Intresttermijn',
      numberofpayments: '# betalingen',
      reg: 'Reg.',
      rog: 'Rog',
      regrog: 'Reg. & Rog',
      fee: 'Ereloon',
      abatement: 'Abattement',
      various: 'Diverse',
      provision: 'Provisie',
      typeofcompany: 'Type venn.',
      capital: 'Kapitaal',
      scale: 'Barema',
      age: 'Leeftijd',
      propertyworth: 'Volle eigendom',
      language: 'Taal',
      region: 'Gewest',
      date: 'Datum',
      vat: 'BTW',
      vatdetailed: '@:label.vat ({details})',
      downpayment: 'Vaste afbetaling',
      yearlyinterestrate: 'Jaarlijkse intrestvoet',
      monthlyinterestrate: 'Maand. intrestvoet',
      settings: 'Instellingen',
      about: 'Over',
      defreg: 'Standaard registratie',
      defvariouscost: 'Standaard diverse kosten',
      phone: 'Telefoon',
      donors: 'Donoren',
      beneficiaries: 'Begunstigden',
      withRenovation: 'Vernieuwing',
      mortgage: 'Hypotheekrecht',
      home: 'Startpagina',
      mobileversion: 'Mobile versie',
      annex: 'Bijlage'
    },
    region: {
      flanders: 'Vlaanderen',
      wallonia: 'Wallonië',
      brussels: 'Brussel'
    },
    action: {
      ok: 'OK',
      cancel: 'Annuleren',
      save: 'Bewaren',
      close: 'Sluiten',
      submit: 'Indienen',
      calculate: 'Bereken'
    },
    selection: {
      periodMonthlyYearly: {
        monthly: 'Maandelijks',
        yearly: 'Jaarlijks'
      },
      donationPartiesRelations: {
        straightline: 'In rechte lijn en tussen partners',
        others: 'Tussen andere personen'
      },
      donationKinds: {
        makingforward: 'Bij vooruitmaking',
        advance: 'Voorschot op erfdeel'
      },
      donationGoodTypes: {
        ordinary: 'Gewoon goed',
        constructionplot: 'Bouwgrond',
        movable: 'Roerend goed'
      },
      donationTypesAndRegions,
      distributionAbatement: {
        actualDistribution: 'Eigenlijke verdeling',
        toCoowner: 'Aan mede-eigenaar'
      },
      companyFormationType: {
        bvOthers: 'B.V. en andere',
        nv: 'N.V.'
      }
    },
    message: {
      fieldrequired: 'Dit veld is verplicht',
      requiredfields: 'Vul alle verplichte velden in',
      settingssaved: 'Je instellingen zijn opgeslagen!'
    },
    lastupdate: 'Laatste update: 21/10/2022',
    results: 'Resultaat',
    error: 'Fout',
    savefailed: 'Opslaan instellingen mislukt.',
    invalidamount: 'Bedrag ongeldig.',
    expired: 'Verstreken',
    remaining: 'Resterend',
    days: 'Dagen',
    selectdate: 'Selecteer datum',
    abandonment: 'Afstand',
    options: 'Opties',
    usufruct: 'Vruchtgebruik',
    bareproperty: 'Naakte eig.'
  },
  fr: {
    language: {
      ned: 'Néerlandais',
      fra: 'Français',
      eng: 'Anglais'
    },
    calculation: {
      finance: 'Financement / Prêt personnel',
      donation: 'Donation',
      exchange: 'Echange',
      emancipation: 'Mainlevée',
      credit: 'Ouverture de crédit',
      distribution: 'Partage',
      loan: 'Prêt',
      sale: 'Vente',
      formation: 'Constitution société',
      fees: 'Honoraires',
      split: 'Division précompte immobilier',
      usufruct: 'Valeur de l\'usufruit'
    },
    label: {
      dataconsult: 'Data Consult SPRL',
      amount: 'Montant',
      paymentsterm: 'Terme',
      intrestrate: 'Taux',
      intrestterm: 'Terme d\'intérêt',
      numberofpayments: 'Paiements',
      reg: 'Reg.',
      rog: 'Dde',
      regrog: 'Reg. & Dde',
      fee: 'Honoraires',
      abatement: 'Abattement',
      various: 'Divers',
      provision: 'Provision',
      typeofcompany: 'Type soc.',
      capital: 'Capital',
      scale: 'Barème',
      age: 'Age usufruitier',
      propertyworth: 'Valeur propriété',
      language: 'Langue',
      region: 'Communauté',
      date: 'Date',
      vat: 'TVA',
      vatdetailed: '@:label.vat ({details})',
      downpayment: 'Menualités fixes',
      yearlyinterestrate: 'Taux d\'intérêt annuel',
      monthlyinterestrate: 'Taux d\'intérêt mensuel',
      settings: 'Configuration',
      about: 'A propos',
      defreg: 'Enreg. par déf.',
      defvariouscost: 'Divers par déf.',
      phone: 'Téléphone',
      donors: 'Donneurs',
      beneficiaries: 'Bénéficiaires',
      withRenovation: 'Rénovation',
      mortgage: 'Droit hypothèques',
      home: 'Page d’accueil',
      mobileversion: 'Version mobile',
      annex: 'Annexe'
    },
    region: {
      flanders: 'Flandres',
      wallonia: 'Wallonie',
      brussels: 'Bruxelles'
    },
    action: {
      ok: 'OK',
      cancel: 'Annuler',
      save: 'Sauvgarder',
      close: 'Fermer',
      submit: 'Soumettre',
      calculate: 'Calculer'
    },
    selection: {
      periodMonthlyYearly: {
        monthly: 'Mensuel',
        yearly: 'Annuel'
      },
      donationPartiesRelations: {
        straightline: 'Ligne directe & entre partenaires',
        others: 'Entre autres personnes'
      },
      donationKinds: {
        makingforward: 'Par préciput',
        advance: 'Avancement d\'hoirie'
      },
      donationGoodTypes: {
        ordinary: 'Bien',
        constructionplot: 'Terrain à bâtir',
        movable: 'Bien meuble'
      },
      donationTypesAndRegions,
      distributionAbatement: {
        actualDistribution: 'Partage effectif',
        toCoowner: 'A copropriétaire'
      },
      companyFormationType: {
        bvOthers: 'S.R.L. et autres',
        nv: 'S.A.'
      }
    },
    message: {
      fieldrequired: 'Ce champ est obligatoire',
      requiredfields: 'S\'il vous plaît remplir tous les champs obligatoires',
      settingssaved: 'Vos paramètres ont été sauvegardés'
    },
    lastupdate: 'Dernière mise à jour : 21/10/2022',
    results: 'Résultats',
    error: 'Erreur',
    savefailed: 'Sauvegarde des préférences échouée',
    invalidamount: 'Montant non valable.',
    expired: 'Passés',
    remaining: 'Restants',
    days: 'Jours',
    selectdate: 'Sélectionner date',
    abandonment: 'Cession',
    options: 'Options',
    usufruct: 'Usufruit',
    bareproperty: 'Prop. nue'
  }
};
