import { Decimal } from 'decimal.js';

export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      return [
        { labelLocale: 'days' },
        {
          labelLocale: 'expired',
          value: this.getPassedDaysFromYearTillDate(params.dateRaw)
        },
        {
          labelLocale: 'remaining',
          value: this.getRemainingDaysOfYearFromDate(params.dateRaw)
        },
        { labelLocale: 'label.amount' },
        {
          labelLocale: 'expired',
          value: this.formatDecimalToCurrency(
            this.getPassedAmountTillDate(params.amount, params.dateRaw)
          )
        },
        {
          labelLocale: 'remaining',
          value: this.formatDecimalToCurrency(
            this.getRemainingAmountFromDate(params.amount, params.dateRaw)
          )
        }
      ];
    },

    getPassedAmountTillDate(amount, date) {
      return new Decimal(amount)
        .dividedBy(this.getNumberDaysOfYearFromDate(date))
        .times(this.getPassedDaysFromYearTillDate(date));
    },

    getRemainingAmountFromDate(amount, date) {
      return new Decimal(amount)
        .minus(this.getPassedAmountTillDate(amount, date));
    },

    getNumberDaysOfYearFromDate(date) {
      return this.isLeapYearFromDate(date) ? 366 : 365;
    },

    getPassedDaysFromYearTillDate(date) {
      const dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
      const monthNumber = date.getMonth();
      const dayOfMonth = date.getDate();
      let dayOfYear = dayCount[monthNumber] + dayOfMonth;
      if (monthNumber > 1 && this.isLeapYearFromDate(date)) {
        dayOfYear++;
      }
      return dayOfYear;
    },

    getRemainingDaysOfYearFromDate(date) {
      return this.getNumberDaysOfYearFromDate(date) - this.getPassedDaysFromYearTillDate(date);
    },

    isLeapYearFromDate(date) {
      const year = date.getFullYear();
      return ((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0);
    }
  }
};
