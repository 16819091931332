export default {
  methods: {
    getOutputResults() {
      const params = this.getCalculationParams();
      const feeAmount = this.getFeeAmount(params.amount, params.feeScale, params.feePercentage);
      const vatAmount = this.calculateVat([feeAmount], params.vatPercentage);
      return [
        {
          labelLocale: 'label.fee',
          value: this.formatNumberToCurrency(feeAmount)
        },
        {
          labelLocale: 'label.vat',
          value: this.formatNumberToCurrency(vatAmount)
        },
        {
          labelLocale: 'label.provision',
          value: this.formatNumberToCurrency(this.sumNumbersArray([feeAmount, vatAmount]))
        }
      ];
    }
  }
};
