<template>
  <div>
    <input-section :calculationItem="activeCalculationItem"></input-section>
    <transition name="fade">
      <output-section v-show="calculated" :calculationItem="activeCalculationItem"></output-section>
    </transition>
    <transition name="slide">
      <notifications
        group="calculation"
        position="bottom right"
      />
    </transition>
  </div>
</template>

<script>
import { SharedCalculationsStore, SharedEventBus } from 'notacalc-shared';
import InputSection from '@/components/view/section/Input.vue';
import OutputSection from '@/components/view/section/Output.vue';

export default {
  components: {
    InputSection,
    OutputSection
  },
  data() {
    return {
      activeCalculationItem: null,
      calculated: false,
      requiredFieldsWarningTimeout: null
    };
  },
  created() {
    SharedEventBus.$on('forminvalid', this.onFormInvalid);
    SharedEventBus.$on('calculated', this.updateCalculatedToTrue);
  },
  beforeDestroy() {
    SharedEventBus.$off('forminvalid', this.onFormInvalid);
    SharedEventBus.$off('calculated', this.updateCalculatedToTrue);
  },
  watch: {
    $route: {
      handler: 'setActiveCalculationFromPath',
      immediate: true
    }
  },
  methods: {
    setActiveCalculationFromPath() {
      const { path } = this.$route;
      const calculationItem = SharedCalculationsStore.filter(
        menuElement => menuElement.path === path
      )[0];
      if (calculationItem) {
        this.activeCalculationItem = calculationItem;
        this.calculated = false;
      }
    },
    updateCalculatedToTrue() {
      this.updateCalculated(true);
    },
    updateCalculated(value) {
      this.calculated = value;
    },
    onFormInvalid() {
      this.updateCalculated(false);
      this.$notify({
        type: 'warn',
        group: 'calculation',
        text: this.$t('message.requiredfields')
      });
    }
  }
};
</script>

<style lang="scss">
  .fade-enter-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to {
    opacity: 0;
  }
  .slide-enter-active, .slide-leave-active {
    transition: all 0.4s ease-in-out;
  }
  .slide-enter, .slide-leave-to {
    transform :  translateY(100px);
  }
</style>
