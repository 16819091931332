import { Decimal } from 'decimal.js';

export default {
    sumNumbersArray(numbersArray) {
      return this.decimalToNumber(
        numbersArray.reduce(
          (accumulator, currentValue) => accumulator.plus(currentValue),
          new Decimal(0)
        )
      );
    },

    decimalToNumber(decimal) {
      return decimal
        .toDecimalPlaces(2)
        .toNumber();
    },

    formatNumberToCurrency(number) {
      return this.formatNumberToLocale(number.toFixed(2));
    },

    formatDecimalToCurrency(decimal) {
      return this.formatNumberToLocale(decimal.toFixed(2));
    },

    formatNumberToLocale(number) {
      const numberParts = number.toString().split('.');
      let wholePart = numberParts[0];
      const decimalPart = numberParts[1];
      wholePart = wholePart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      return `${wholePart},${decimalPart}`;
    }
};
