export default {
  languages: [
    { value: 'nl', locale: 'language.ned' },
    { value: 'fr', locale: 'language.fra' },
    { value: 'en', locale: 'language.eng' }
  ],
  periodMonthlyYearly: [
    { value: 'monthly', locale: 'selection.periodMonthlyYearly.monthly' },
    { value: 'yearly', locale: 'selection.periodMonthlyYearly.yearly' }
  ],
  donationPartiesRelations: [
    { value: 'RL', locale: 'selection.donationPartiesRelations.straightline' },
    { value: 'AP', locale: 'selection.donationPartiesRelations.others' }
  ],
  donationKinds: [
    { value: 'makingForward', locale: 'selection.donationKinds.makingforward' },
    { value: 'advanceInheritance', locale: 'selection.donationKinds.advance' }
  ],
  regions: [
    { value: 'flanders', locale: 'region.flanders' },
    { value: 'wallonia', locale: 'region.wallonia' },
    { value: 'brussels', locale: 'region.brussels' }
  ],
  // The first letter from the "value" represents the region (W=Wallonia, B=Brussels, V=Flanders)
  // and the second - the type of the good to be donated (G=ordinary goods, B=Construction plot,
  // R=Movable property)
  donationTypesAndRegions: [
    { value: 'BG', locale: 'selection.donationTypesAndRegions.BG' },
    { value: 'BR', locale: 'selection.donationTypesAndRegions.BR' },
    { value: 'WG', locale: 'selection.donationTypesAndRegions.WG' },
    { value: 'WR', locale: 'selection.donationTypesAndRegions.WR' },
    { value: 'VG', locale: 'selection.donationTypesAndRegions.VG' },
    { value: 'VB', locale: 'selection.donationTypesAndRegions.VB' },
    { value: 'VR', locale: 'selection.donationTypesAndRegions.VR' }
  ],
  fees10050: [
    { value: 100, title: '100%' },
    { value: 50, title: '50%' }
  ],
  regHypSetting: [
    { value: 12.5, title: '12,5%' },
    { value: 10, title: '10%' }
  ],
  regHypSale: [
    { value: 15, title: '15%' },
    { value: 12.5, title: '12,5%' },
    { value: 10, title: '10%' },
    { value: 8, title: '8%' },
    { value: 7, title: '7%' },
    { value: 6, title: '6%' },
    { value: 5, title: '5%' },
    { value: 1.5, title: '1,5%' },
    { value: 1, title: '1%' }
  ],
  regHypExchange: [
    { value: 12.5, title: '12,5%' },
    { value: 10, title: '10%' },
    { value: 7, title: '7%' },
    { value: 6, title: '6%' },
    { value: 5, title: '5%' },
    { value: 1.5, title: '1,5%' },
    { value: 1, title: '1%' }
  ],
  regHypOpeningCredit: [
    { value: 1.3, title: '1,3%' },
    { value: 1, title: '1%' }
  ],
  regHypDistribution: [
    { value: 2.5, title: '2,5%' },
    { value: 1, title: '1%' }
  ],
  distributionAbatement: [
    { value: 'actualDistribution', locale: 'selection.distributionAbatement.actualDistribution' },
    { value: 'toCoowner', locale: 'selection.distributionAbatement.toCoowner' }
  ],
  regHypLoan: [
    { value: 1.3, title: '1,3%' },
    { value: 1, title: '1%' }
  ],
  saleAbatement: [
    { value: 0, title: '-' },
    { value: 4800, title: '4800' },
    { value: 5600, title: '5600' },
    { value: 20000, title: '20000' },
    { value: 175000, title: '175000' }
  ],
  regHypCompanyFormation: [
    { value: 12.5, title: '12,5%' },
    { value: 10, title: '10%' },
    { value: 0, title: '0%' }
  ],
  companyFormationType: [
    { value: 'bvOthers', locale: 'selection.companyFormationType.bvOthers' },
    { value: 'nv', locale: 'selection.companyFormationType.nv' }
  ],
  rogKinds: [
    { value: 7.5, title: '7,5€' },
    { value: 50, title: '50€' },
    { value: 95, title: '95€' }
  ],
  annexKinds: [
    { value: 0, title: '0€' },
    { value: 100, title: '100€' }
  ],
  feeScales: [
    { value: 'A', title: 'A' },
    { value: 'B', title: 'B' },
    { value: 'C', title: 'C' },
    { value: 'D', title: 'D' },
    { value: 'E', title: 'E' },
    { value: 'F', title: 'F' },
    { value: 'G', title: 'G' },
    { value: 'H', title: 'H' },
    { value: 'I', title: 'I' },
    { value: 'J', title: 'J' },
    { value: 'K', title: 'K' },
    { value: 'L', title: 'L' },
    { value: 'LBIS', title: 'Lbis' },
    { value: 'M', title: 'M' },
    { value: 'MBIS', title: 'Mbis' }
  ],
  usufructAgeIntervals: [
    { id: 1, title: '0-20', coefficient: 18 },
    { id: 2, title: '21-30', coefficient: 17 },
    { id: 3, title: '31-40', coefficient: 16 },
    { id: 4, title: '41-50', coefficient: 14 },
    { id: 5, title: '51-55', coefficient: 13 },
    { id: 6, title: '56-60', coefficient: 11 },
    { id: 7, title: '61-65', coefficient: 9.5 },
    { id: 8, title: '66-70', coefficient: 8 },
    { id: 9, title: '71-75', coefficient: 6 },
    { id: 10, title: '76-80', coefficient: 4 },
    { id: 11, title: '&gt; 80', coefficient: 2 }
  ]
};
